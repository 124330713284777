.pac-container {
    z-index: 1099 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
}

.modal-body {
    .page-content {
        padding: 0;

        .container,
        .container-fluid,
        .container-sm,
        .container-md,
        .container-lg,
        .container-xl,
        .container-xxl {
            padding: 0;

            .card {
                box-shadow: none;
                margin-bottom: 0;
            }
        }
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    #printSection,
    #printSection * {
        visibility: visible;
    }

    #printSection {
        position: absolute;
        left: 0;
        top: 0;
    }
}

// workout view css start

// html, body {
//     padding: 0;
//     margin: 0;
// }

// body {
//     font-family: Arial, Helvetica, sans-serif;
//     background-color: #000000;
// }

.main-content-wrapper {

    .main-contents {
        width: 800px;
        margin: auto;
        background-color: #000;

        @media only screen and (max-width: 1110px) {
            width: auto;
        }

        @media only screen and (max-width: 800px) {
            width: 90%;
        }

        .header-home {
            padding: 0 30px;
            background-color: #000;

            div {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    font-size: 30px;
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: 900;
                }
            }
        }

        .content-heading {
            background-color: #d50c38;
            padding: 15px 30px;
            color: #fff;

            h4 {
                font-size: 18px;
                text-transform: uppercase;
                margin: 0;
                font-weight: 600;
                color: #fff;

                span {
                    text-transform: lowercase;
                    font-weight: normal;
                    opacity: 0.8;
                    margin-left: 5px;
                }
            }

            p {
                margin: 5px 0 0;
            }
        }


        .content-details {
            background-color: #fff;
            padding: 20px 30px;

            h2 {
                color: #000;
                font-size: 25px;
                font-weight: 600;
                text-transform: uppercase;
                margin: 0;
                margin-bottom: 40px;

                span {
                    color: #999;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }

            p {
                margin: 0;
                margin-bottom: 40px
            }

            .plan.diet-plan {
                margin-bottom: 0px;
            }

            .plan {
                margin-bottom: 35px;
                position: relative;


                .plan-name {
                    border-bottom: 1px dashed #b1b1b1;

                    h4 {
                        padding: 0 0 0 10px;
                        border-left: 8px solid #000;
                        font-size: 18px;
                        text-transform: uppercase;
                        margin-bottom: 12px;
                        color: #d50c38;
                        font-weight: 900;
                    }
                }

                .plan-details {
                    display: flex;
                    border-bottom: 1px dashed #b1b1b1;
                    padding: 12px 0;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 18px;

                    p {
                        margin: 0;

                        &:first-child {
                            padding-right: 20px;
                            // white-space: nowrap;
                            white-space: normal;
                            flex: 1;
                        }
                    }
                }

                .exercise-choice {
                    position: absolute;
                    left: 50%;
                    bottom: -32%;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background: #d50a38;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 500;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 35px;
                    z-index: 1;
                }

            }


            .nutrients-wrapper {
                border-bottom: 1px dashed #7fb7eb;
                margin-bottom: 30px;

                .nutrients-title {
                    padding: 10px;
                    background: #dcedfc;

                    h5 {
                        font-size: 16px;
                        text-transform: uppercase;
                        font-weight: 600;
                        color: #000;
                    }
                }

                .nutrients-details {
                    padding: 10px;

                    p {
                        margin-bottom: 0px;
                        display: block;
                        // flex-wrap: wrap;
                        font-size: 16px;

                        p {
                            margin-right: 15px;

                            span {
                                font-size: 14px !important;

                                strong {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }

                &:first-child {
                    margin-top: 20px;
                }
            }
        }


    }
}



@media only screen and (max-width: 700px) {

    .header-home div {
        flex-direction: column-reverse;
    }

    .header-home p {
        margin-top: 10px;
        text-align: center;
        font-size: 25px;
    }

    .content-heading {
        text-align: center;
    }

    .content-heading span {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
    }
}

// workout view css end

.member-name {
    font-size: 18px;
    font-weight: bold;
    padding: inherit
}

.member-plan {
    display: flex;
    justify-content: end
}

.memberlist-table {
    .react-bootstrap-table {
        overflow-x: scroll;
        overflow-y: visible;
        padding-bottom: 5px;
        scroll-behavior: smooth;

        .table {
            thead th {
                position: -webkit-sticky;
                position: sticky;
                top: 0;

                @media (max-width: 950px) {
                    position: static;
                }
            }

            thead,
            tbody {

                th:first-child,
                td:first-child {
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0;
                    top: auto;
                    min-width: 70px;
                    width: auto;
                    z-index: 2;
                    background-color: #fff;

                    @media (max-width: 950px) {
                        position: static;
                    }
                }

                th:nth-child(2),
                td:nth-child(2) {
                    position: -webkit-sticky;
                    position: sticky;
                    top: auto;
                    min-width: 150px;
                    width: auto;
                    left: 70px;
                    z-index: 3;
                    background-color: #fff;

                    @media (max-width: 950px) {
                        position: static;
                    }
                }

                th:nth-child(3),
                td:nth-child(3) {
                    position: -webkit-sticky;
                    position: sticky;
                    left: 220px;
                    top: auto;
                    min-width: 120px;
                    width: auto;
                    z-index: 4;
                    background-color: #fff;
                    -webkit-box-shadow: 11px 0px 7px -8px rgba(0, 0, 0, 0.07);
                    -moz-box-shadow: 11px 0px 7px -8px rgba(0, 0, 0, 0.07);
                    box-shadow: 11px 0px 7px -8px rgba(0, 0, 0, 0.07);

                    @media (max-width: 950px) {
                        position: static;
                    }
                }
            }
        }
    }
}

.membership-table {
    thead th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;

        @media (max-width: 950px) {
            position: static;
        }
    }

    thead,
    tbody {

        th:first-child,
        td:first-child {
            position: -webkit-sticky;
            position: sticky;
            left: 0;
            top: auto;
            min-width: 70px;
            width: auto;
            z-index: 2;
            background-color: #fff;

            @media (max-width: 950px) {
                position: static;
            }
        }

        th:nth-child(2),
        td:nth-child(2) {
            position: -webkit-sticky;
            position: sticky;
            top: auto;
            min-width: 150px;
            width: auto;
            left: 70px;
            z-index: 3;
            background-color: #fff;

            @media (max-width: 950px) {
                position: static;
            }
        }

        th:nth-child(3),
        td:nth-child(3) {
            position: -webkit-sticky;
            position: sticky;
            left: 220px;
            top: auto;
            min-width: 300px;
            width: auto;
            z-index: 4;
            background-color: #fff;

            @media (max-width: 950px) {
                position: static;
            }
        }

        th:nth-child(4),
        td:nth-child(4) {
            position: -webkit-sticky;
            position: sticky;
            left: 524px;
            top: auto;
            min-width: 130px;
            width: auto;
            z-index: 5;
            background-color: #fff;

            @media (max-width: 950px) {
                position: static;
            }
        }

        // th:nth-child(5), td:nth-child(5) {
        //     position: -webkit-sticky;
        //     position: sticky;
        //     left: 654px;
        //     top: auto;
        //     min-width: 120px;
        //     width: auto;
        //     z-index: 6;
        //     background-color: #fff;
        //     -webkit-box-shadow: 11px 0px 7px -8px rgba(0,0,0,0.07);
        //     -moz-box-shadow: 11px 0px 7px -8px rgba(0,0,0,0.07);
        //     box-shadow: 11px 0px 7px -8px rgba(0,0,0,0.07);
        // }
    }

    @media (max-width: 950px) {}
}

.workout-chart,
.diet-chart-table,
.body-composition-table {
    .card {
        padding: 0;
        box-shadow: none;

        .card-body {
            margin: 0 -45px;
            padding: 0 20px;

            .react-bootstrap-table {

                th:first-child,
                td:first-child {
                    display: none;
                }
            }
        }
    }
}

.trainer-table {
    .card {
        padding: 0;
        box-shadow: none;

        .card-body {
            margin: 0 -9px;
            padding: 0 20px;

            .react-bootstrap-table {

                th:first-child,
                td:first-child {
                    display: none;
                }
            }
        }
    }
}

.custom-tab {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    flex-wrap: nowrap;
}

.bx {
    font-size: 18px;
}

/*** Utility Custom classes ***/
.text-uppercase {
    text-transform: uppercase;
}

.font-semibold {
    font-weight: 600;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

.size-24 {
    font-size: 24px;
}

.color-success {
    color: #34c38f;
}

.color-danger {
    color: #f46a6a;
}

.p-l-4 {
    display: inline-block;
    padding-left: 4px;
}

.p-r-2 {
    display: inline-block;
    padding-left: 2px;
}

.cursor-pointer {
    cursor: pointer;
}

.filter-date-width {
    width: auto;
}

.filter {
    display: flex;
    justify-content: end;
}

.margin-top {
    margin-top: 50%;
}

.fl-right {
    float: right;
}

.ml-2 {
    margin-left: 2%;
}

.h-w-5 {
    height: 50% !important;
    width: 50% !important;
}

.custom-invalid {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f46a6a;
}

.m-r-3 {
    margin-right: 3%;
}

.activity-tab {
    margin-left: auto;
    margin-bottom: 3%;
}

.m-t-4 {
    margin-top: 4%;
}

.col-fixed {
    .table {
        thead th {
            position: -webkit-sticky;
            position: sticky;
            top: 0;

            @media (max-width: 950px) {
                position: static;
            }
        }

        thead,
        tbody {

            th:first-child,
            td:first-child {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                top: auto;
                min-width: 70px;
                width: auto;
                z-index: 2;
                background-color: #fff;

                @media (max-width: 950px) {
                    position: static;
                }
            }

            th:nth-child(2),
            td:nth-child(2) {
                position: -webkit-sticky;
                position: sticky;
                top: auto;
                min-width: 161px;
                width: auto;
                left: 70px;
                z-index: 3;
                background-color: #fff;

                @media (max-width: 950px) {
                    position: static;
                }
            }

            th:nth-child(3),
            td:nth-child(3) {
                position: -webkit-sticky;
                position: sticky;
                left: 231px;
                top: auto;
                min-width: 120px;
                width: auto;
                z-index: 4;
                background-color: #fff;
                -webkit-box-shadow: 11px 0px 7px -8px rgba(0, 0, 0, 0.07);
                -moz-box-shadow: 11px 0px 7px -8px rgba(0, 0, 0, 0.07);
                box-shadow: 11px 0px 7px -8px rgba(0, 0, 0, 0.07);

                @media (max-width: 950px) {
                    position: static;
                }
            }
        }
    }
}

.gst-title {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 2px;
}

.apexcharts-menu-item {
    padding: 6px 6px !important;
    font-size: 12px !important;
    cursor: pointer !important;
}

.ms-15 {
    margin-left: 15rem !important;
}

.font-size-10 {
    font-size: 10px !important;
}